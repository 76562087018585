@font-face {
  src: url('/static/fonts/seti.woff') format('woff');
  font-family: 'seti';
  font-weight: normal;
  font-style: normal;
}
.show-file-icons .file-icon::before,
.show-file-icons .folder-icon::before,
.show-file-icons .rootfolder-icon::before {
  font-family: 'seti';
  font-size: 150%;
}
.show-file-icons .file-icon::before,
.show-file-icons .txt-ext-file-icon.ext-file-icon.file-icon::before {
  color: #d4d7d6;
  content: '\E01D';
}
.show-file-icons .bat-lang-file-icon.file-icon::before {
  color: #519aba;
  content: '\E086';
}
.show-file-icons .clojure-lang-file-icon.file-icon::before {
  color: #8dc149;
  content: '\E010';
}
.show-file-icons .coffeescript-lang-file-icon.file-icon::before {
  color: #cbcb41;
  content: '\E012';
}
.show-file-icons .c-lang-file-icon.file-icon::before {
  color: #519aba;
  content: '\E009';
}
.show-file-icons .cpp-lang-file-icon.file-icon::before {
  color: #519aba;
  content: '\E016';
}
.show-file-icons .csharp-lang-file-icon.file-icon::before {
  color: #519aba;
  content: '\E008';
}
.show-file-icons .css-lang-file-icon.file-icon::before,
.show-file-icons
  .css\.map-ext-file-icon.map-ext-file-icon.ext-file-icon.file-icon::before,
.show-file-icons .sss-ext-file-icon.ext-file-icon.file-icon::before {
  color: #519aba;
  content: '\E019';
}
.show-file-icons .dockerfile-lang-file-icon.file-icon::before {
  color: #519aba;
  content: '\E01F';
}
.show-file-icons .fsharp-lang-file-icon.file-icon::before {
  color: #519aba;
  content: '\E028';
}
.show-file-icons .go-lang-file-icon.file-icon::before {
  color: #519aba;
  content: '\E033';
}
.show-file-icons .groovy-lang-file-icon.file-icon::before,
.show-file-icons .gsp-ext-file-icon.ext-file-icon.file-icon::before {
  color: #8dc149;
  content: '\E035';
}
.show-file-icons .handlebars-lang-file-icon.file-icon::before,
.show-file-icons .mustache-ext-file-icon.ext-file-icon.file-icon::before,
.show-file-icons .stache-ext-file-icon.ext-file-icon.file-icon::before {
  color: #e37933;
  content: '\E058';
}
.show-file-icons .html-lang-file-icon.file-icon::before {
  color: #e37933;
  content: '\E03E';
}
.show-file-icons .properties-lang-file-icon.file-icon::before,
.show-file-icons .java-lang-file-icon.file-icon::before,
.show-file-icons .class-ext-file-icon.ext-file-icon.file-icon::before,
.show-file-icons .classpath-ext-file-icon.ext-file-icon.file-icon::before {
  color: #cc3e44;
  content: '\E046';
}
.show-file-icons .javascriptreact-lang-file-icon.file-icon::before,
.show-file-icons .typescriptreact-lang-file-icon.file-icon::before,
.show-file-icons .cjsx-ext-file-icon.ext-file-icon.file-icon::before {
  color: #519aba;
  content: '\E069';
}
.show-file-icons .javascript-lang-file-icon.file-icon::before,
.show-file-icons
  .js\.map-ext-file-icon.map-ext-file-icon.ext-file-icon.file-icon::before,
.show-file-icons .es-ext-file-icon.ext-file-icon.file-icon::before,
.show-file-icons .es5-ext-file-icon.ext-file-icon.file-icon::before,
.show-file-icons .es7-ext-file-icon.ext-file-icon.file-icon::before {
  color: #cbcb41;
  content: '\E047';
}
.show-file-icons .json-lang-file-icon.file-icon::before,
.show-file-icons .jsonc-lang-file-icon.file-icon::before,
.show-file-icons .cson-ext-file-icon.ext-file-icon.file-icon::before {
  color: #cbcb41;
  content: '\E04B';
}
.show-file-icons .less-lang-file-icon.file-icon::before {
  color: #519aba;
  content: '\E04E';
}
.show-file-icons .lua-lang-file-icon.file-icon::before {
  color: #519aba;
  content: '\E053';
}
.show-file-icons .makefile-lang-file-icon.file-icon::before {
  color: #e37933;
  content: '\E054';
}
.show-file-icons .markdown-lang-file-icon.file-icon::before {
  color: #519aba;
  content: '\E055';
}
.show-file-icons .objective-c-lang-file-icon.file-icon::before {
  color: #cbcb41;
  content: '\E009';
}
.show-file-icons .perl-lang-file-icon.file-icon::before {
  color: #519aba;
  content: '\E060';
}
.show-file-icons .php-lang-file-icon.file-icon::before,
.show-file-icons
  .php\.inc-ext-file-icon.inc-ext-file-icon.ext-file-icon.file-icon::before {
  color: #a074c4;
  content: '\E062';
}
.show-file-icons .powershell-lang-file-icon.file-icon::before {
  color: #519aba;
  content: '\E063';
}
.show-file-icons .jade-lang-file-icon.file-icon::before {
  color: #cc3e44;
  content: '\E045';
}
.show-file-icons .python-lang-file-icon.file-icon::before {
  color: #519aba;
  content: '\E067';
}
.show-file-icons .ruby-lang-file-icon.file-icon::before {
  color: #cc3e44;
  content: '\E06B';
}
.show-file-icons .rust-lang-file-icon.file-icon::before {
  color: #6d8086;
  content: '\E06C';
}
.show-file-icons .scss-lang-file-icon.file-icon::before,
.show-file-icons .sass-ext-file-icon.ext-file-icon.file-icon::before,
.show-file-icons
  .sass-lint\.yml-name-file-icon.yml-ext-file-icon.ext-file-icon.file-icon::before {
  color: #f55385;
  content: '\E06E';
}
.show-file-icons .shellscript-lang-file-icon.file-icon::before,
.show-file-icons .fish-ext-file-icon.ext-file-icon.file-icon::before {
  color: #4d5a5e;
  content: '\E073';
}
.show-file-icons .sql-lang-file-icon.file-icon::before {
  color: #f55385;
  content: '\E01C';
}
.show-file-icons .swift-lang-file-icon.file-icon::before {
  color: #e37933;
  content: '\E07A';
}
.show-file-icons .typescript-lang-file-icon.file-icon::before {
  color: #519aba;
  content: '\E080';
}
.show-file-icons .xml-lang-file-icon.file-icon::before {
  color: #e37933;
  content: '\E089';
}
.show-file-icons .yaml-lang-file-icon.file-icon::before {
  color: #a074c4;
  content: '\E08B';
}
.show-file-icons .bsl-ext-file-icon.ext-file-icon.file-icon::before {
  color: #cc3e44;
  content: '\E007';
}
.show-file-icons .mdo-ext-file-icon.ext-file-icon.file-icon::before {
  color: #cc3e44;
  content: '\E057';
}
.show-file-icons .asm-ext-file-icon.ext-file-icon.file-icon::before,
.show-file-icons .s-ext-file-icon.ext-file-icon.file-icon::before {
  color: #cc3e44;
  content: '\E003';
}
.show-file-icons .h-ext-file-icon.ext-file-icon.file-icon::before {
  color: #a074c4;
  content: '\E009';
}
.show-file-icons .hh-ext-file-icon.ext-file-icon.file-icon::before,
.show-file-icons .hpp-ext-file-icon.ext-file-icon.file-icon::before,
.show-file-icons .hxx-ext-file-icon.ext-file-icon.file-icon::before {
  color: #a074c4;
  content: '\E016';
}
.show-file-icons .edn-ext-file-icon.ext-file-icon.file-icon::before {
  color: #519aba;
  content: '\E010';
}
.show-file-icons .cfc-ext-file-icon.ext-file-icon.file-icon::before,
.show-file-icons .cfm-ext-file-icon.ext-file-icon.file-icon::before {
  color: #519aba;
  content: '\E014';
}
.show-file-icons .config-ext-file-icon.ext-file-icon.file-icon::before,
.show-file-icons .cfg-ext-file-icon.ext-file-icon.file-icon::before,
.show-file-icons .conf-ext-file-icon.ext-file-icon.file-icon::before,
.show-file-icons .toml-ext-file-icon.ext-file-icon.file-icon::before,
.show-file-icons .direnv-ext-file-icon.ext-file-icon.file-icon::before,
.show-file-icons .env-ext-file-icon.ext-file-icon.file-icon::before,
.show-file-icons .static-ext-file-icon.ext-file-icon.file-icon::before,
.show-file-icons .editorconfig-ext-file-icon.ext-file-icon.file-icon::before,
.show-file-icons .slugignore-ext-file-icon.ext-file-icon.file-icon::before,
.show-file-icons .htaccess-ext-file-icon.ext-file-icon.file-icon::before,
.show-file-icons
  .mime\.types-name-file-icon.types-ext-file-icon.ext-file-icon.file-icon::before {
  color: #6d8086;
  content: '\E015';
}
.show-file-icons .cr-ext-file-icon.ext-file-icon.file-icon::before {
  color: #d4d7d6;
  content: '\E017';
}
.show-file-icons .ecr-ext-file-icon.ext-file-icon.file-icon::before,
.show-file-icons .slang-ext-file-icon.ext-file-icon.file-icon::before {
  color: #d4d7d6;
  content: '\E018';
}
.show-file-icons .csv-ext-file-icon.ext-file-icon.file-icon::before {
  color: #8dc149;
  content: '\E01A';
}
.show-file-icons .xls-ext-file-icon.ext-file-icon.file-icon::before,
.show-file-icons .xlsx-ext-file-icon.ext-file-icon.file-icon::before {
  color: #8dc149;
  content: '\E088';
}
.show-file-icons .cake-ext-file-icon.ext-file-icon.file-icon::before {
  color: #cc3e44;
  content: '\E00A';
}
.show-file-icons .ctp-ext-file-icon.ext-file-icon.file-icon::before {
  color: #cc3e44;
  content: '\E00B';
}
.show-file-icons .d-ext-file-icon.ext-file-icon.file-icon::before {
  color: #cc3e44;
  content: '\E01B';
}
.show-file-icons .doc-ext-file-icon.ext-file-icon.file-icon::before,
.show-file-icons .docx-ext-file-icon.ext-file-icon.file-icon::before {
  color: #519aba;
  content: '\E087';
}
.show-file-icons .ejs-ext-file-icon.ext-file-icon.file-icon::before {
  color: #cbcb41;
  content: '\E021';
}
.show-file-icons .ex-ext-file-icon.ext-file-icon.file-icon::before {
  color: #a074c4;
  content: '\E022';
}
.show-file-icons .exs-ext-file-icon.ext-file-icon.file-icon::before {
  color: #a074c4;
  content: '\E023';
}
.show-file-icons .elm-ext-file-icon.ext-file-icon.file-icon::before {
  color: #519aba;
  content: '\E024';
}
.show-file-icons .ico-ext-file-icon.ext-file-icon.file-icon::before {
  color: #cbcb41;
  content: '\E029';
}
.show-file-icons .gitignore-ext-file-icon.ext-file-icon.file-icon::before,
.show-file-icons .gitconfig-ext-file-icon.ext-file-icon.file-icon::before,
.show-file-icons .gitkeep-ext-file-icon.ext-file-icon.file-icon::before,
.show-file-icons .gitattributes-ext-file-icon.ext-file-icon.file-icon::before,
.show-file-icons .gitmodules-ext-file-icon.ext-file-icon.file-icon::before {
  color: #41535b;
  content: '\E02E';
}
.show-file-icons .slide-ext-file-icon.ext-file-icon.file-icon::before,
.show-file-icons .article-ext-file-icon.ext-file-icon.file-icon::before {
  color: #519aba;
  content: '\E032';
}
.show-file-icons .gradle-ext-file-icon.ext-file-icon.file-icon::before {
  color: #8dc149;
  content: '\E034';
}
.show-file-icons .haml-ext-file-icon.ext-file-icon.file-icon::before {
  color: #cc3e44;
  content: '\E039';
}
.show-file-icons .hs-ext-file-icon.ext-file-icon.file-icon::before,
.show-file-icons .lhs-ext-file-icon.ext-file-icon.file-icon::before {
  color: #a074c4;
  content: '\E03A';
}
.show-file-icons .hx-ext-file-icon.ext-file-icon.file-icon::before {
  color: #e37933;
  content: '\E03B';
}
.show-file-icons .hxs-ext-file-icon.ext-file-icon.file-icon::before {
  color: #cbcb41;
  content: '\E03B';
}
.show-file-icons .hxp-ext-file-icon.ext-file-icon.file-icon::before {
  color: #519aba;
  content: '\E03B';
}
.show-file-icons .hxml-ext-file-icon.ext-file-icon.file-icon::before {
  color: #a074c4;
  content: '\E03B';
}
.show-file-icons
  .spec\.js-ext-file-icon.js-ext-file-icon.ext-file-icon.file-icon::before {
  color: #e37933;
  content: '\E047';
}
.show-file-icons .jinja-ext-file-icon.ext-file-icon.file-icon::before,
.show-file-icons .jinja2-ext-file-icon.ext-file-icon.file-icon::before {
  color: #cc3e44;
  content: '\E049';
}
.show-file-icons .jl-ext-file-icon.ext-file-icon.file-icon::before {
  color: #a074c4;
  content: '\E04C';
}
.show-file-icons .liquid-ext-file-icon.ext-file-icon.file-icon::before {
  color: #8dc149;
  content: '\E050';
}
.show-file-icons .ls-ext-file-icon.ext-file-icon.file-icon::before {
  color: #519aba;
  content: '\E051';
}
.show-file-icons .njk-ext-file-icon.ext-file-icon.file-icon::before,
.show-file-icons .nunjucks-ext-file-icon.ext-file-icon.file-icon::before,
.show-file-icons .nunjs-ext-file-icon.ext-file-icon.file-icon::before,
.show-file-icons .nunj-ext-file-icon.ext-file-icon.file-icon::before,
.show-file-icons .njs-ext-file-icon.ext-file-icon.file-icon::before,
.show-file-icons .nj-ext-file-icon.ext-file-icon.file-icon::before {
  color: #8dc149;
  content: '\E05C';
}
.show-file-icons
  .npm-debug\.log-ext-file-icon.log-ext-file-icon.ext-file-icon.file-icon::before {
  color: #41535b;
  content: '\E05A';
}
.show-file-icons .npmignore-ext-file-icon.ext-file-icon.file-icon::before,
.show-file-icons .npmrc-ext-file-icon.ext-file-icon.file-icon::before {
  color: #cc3e44;
  content: '\E05A';
}
.show-file-icons .ml-ext-file-icon.ext-file-icon.file-icon::before,
.show-file-icons .mli-ext-file-icon.ext-file-icon.file-icon::before,
.show-file-icons .cmx-ext-file-icon.ext-file-icon.file-icon::before,
.show-file-icons .cmxa-ext-file-icon.ext-file-icon.file-icon::before {
  color: #e37933;
  content: '\E05D';
}
.show-file-icons .odata-ext-file-icon.ext-file-icon.file-icon::before {
  color: #e37933;
  content: '\E05E';
}
.show-file-icons .pug-ext-file-icon.ext-file-icon.file-icon::before {
  color: #cc3e44;
  content: '\E065';
}
.show-file-icons .pp-ext-file-icon.ext-file-icon.file-icon::before,
.show-file-icons .epp-ext-file-icon.ext-file-icon.file-icon::before {
  color: #cbcb41;
  content: '\E066';
}
.show-file-icons .erb-ext-file-icon.ext-file-icon.file-icon::before,
.show-file-icons
  .erb\.html-ext-file-icon.html-ext-file-icon.ext-file-icon.file-icon::before,
.show-file-icons
  .html\.erb-ext-file-icon.erb-ext-file-icon.ext-file-icon.file-icon::before {
  color: #cc3e44;
  content: '\E03F';
}
.show-file-icons .springbeans-ext-file-icon.ext-file-icon.file-icon::before {
  color: #8dc149;
  content: '\E076';
}
.show-file-icons .slim-ext-file-icon.ext-file-icon.file-icon::before {
  color: #e37933;
  content: '\E074';
}
.show-file-icons
  .smarty\.tpl-ext-file-icon.tpl-ext-file-icon.ext-file-icon.file-icon::before {
  color: #cbcb41;
  content: '\E075';
}
.show-file-icons .sbt-ext-file-icon.ext-file-icon.file-icon::before {
  color: #519aba;
  content: '\E06F';
}
.show-file-icons .scala-ext-file-icon.ext-file-icon.file-icon::before {
  color: #cc3e44;
  content: '\E070';
}
.show-file-icons .sol-ext-file-icon.ext-file-icon.file-icon::before {
  color: #519aba;
  content: '\E027';
}
.show-file-icons .styl-ext-file-icon.ext-file-icon.file-icon::before {
  color: #8dc149;
  content: '\E077';
}
.show-file-icons .tf-ext-file-icon.ext-file-icon.file-icon::before,
.show-file-icons
  .tf\.json-ext-file-icon.json-ext-file-icon.ext-file-icon.file-icon::before,
.show-file-icons .tfvars-ext-file-icon.ext-file-icon.file-icon::before {
  color: #a074c4;
  content: '\E07B';
}
.show-file-icons .tex-ext-file-icon.ext-file-icon.file-icon::before {
  color: #519aba;
  content: '\E07C';
}
.show-file-icons .sty-ext-file-icon.ext-file-icon.file-icon::before {
  color: #cbcb41;
  content: '\E07C';
}
.show-file-icons .dtx-ext-file-icon.ext-file-icon.file-icon::before {
  color: #e37933;
  content: '\E07C';
}
.show-file-icons .ins-ext-file-icon.ext-file-icon.file-icon::before {
  color: #d4d7d6;
  content: '\E07C';
}
.show-file-icons .twig-ext-file-icon.ext-file-icon.file-icon::before {
  color: #8dc149;
  content: '\E07F';
}
.show-file-icons
  .spec\.ts-ext-file-icon.ts-ext-file-icon.ext-file-icon.file-icon::before {
  color: #cbcb41;
  content: '\E080';
}
.show-file-icons .vala-ext-file-icon.ext-file-icon.file-icon::before,
.show-file-icons .vapi-ext-file-icon.ext-file-icon.file-icon::before {
  color: #6d8086;
  content: '\E081';
}
.show-file-icons .vue-ext-file-icon.ext-file-icon.file-icon::before {
  color: #8dc149;
  content: '\E083';
}
.show-file-icons .jar-ext-file-icon.ext-file-icon.file-icon::before {
  color: #cc3e44;
  content: '\E08C';
}
.show-file-icons .zip-ext-file-icon.ext-file-icon.file-icon::before {
  color: #6d8086;
  content: '\E08C';
}
.show-file-icons .wgt-ext-file-icon.ext-file-icon.file-icon::before {
  color: #519aba;
  content: '\E085';
}
.show-file-icons .ai-ext-file-icon.ext-file-icon.file-icon::before {
  color: #cbcb41;
  content: '\E041';
}
.show-file-icons .psd-ext-file-icon.ext-file-icon.file-icon::before {
  color: #519aba;
  content: '\E061';
}
.show-file-icons .pdf-ext-file-icon.ext-file-icon.file-icon::before {
  color: #cc3e44;
  content: '\E05F';
}
.show-file-icons .eot-ext-file-icon.ext-file-icon.file-icon::before,
.show-file-icons .ttf-ext-file-icon.ext-file-icon.file-icon::before,
.show-file-icons .woff-ext-file-icon.ext-file-icon.file-icon::before,
.show-file-icons .woff2-ext-file-icon.ext-file-icon.file-icon::before {
  color: #cc3e44;
  content: '\E02D';
}
.show-file-icons .gif-ext-file-icon.ext-file-icon.file-icon::before,
.show-file-icons .jpg-ext-file-icon.ext-file-icon.file-icon::before,
.show-file-icons .jpeg-ext-file-icon.ext-file-icon.file-icon::before,
.show-file-icons .png-ext-file-icon.ext-file-icon.file-icon::before,
.show-file-icons .pxm-ext-file-icon.ext-file-icon.file-icon::before,
.show-file-icons .svgx-ext-file-icon.ext-file-icon.file-icon::before {
  color: #a074c4;
  content: '\E042';
}
.show-file-icons .svg-ext-file-icon.ext-file-icon.file-icon::before {
  color: #a074c4;
  content: '\E079';
}
.show-file-icons .sublime-project-ext-file-icon.ext-file-icon.file-icon::before,
.show-file-icons
  .sublime-workspace-ext-file-icon.ext-file-icon.file-icon::before {
  color: #e37933;
  content: '\E078';
}
.show-file-icons .component-ext-file-icon.ext-file-icon.file-icon::before,
.show-file-icons .cls-ext-file-icon.ext-file-icon.file-icon::before {
  color: #519aba;
  content: '\E06D';
}
.show-file-icons .mov-ext-file-icon.ext-file-icon.file-icon::before,
.show-file-icons .ogv-ext-file-icon.ext-file-icon.file-icon::before,
.show-file-icons .webm-ext-file-icon.ext-file-icon.file-icon::before,
.show-file-icons .avi-ext-file-icon.ext-file-icon.file-icon::before,
.show-file-icons .mpg-ext-file-icon.ext-file-icon.file-icon::before,
.show-file-icons .mp4-ext-file-icon.ext-file-icon.file-icon::before {
  color: #f55385;
  content: '\E082';
}
.show-file-icons .mp3-ext-file-icon.ext-file-icon.file-icon::before,
.show-file-icons .ogg-ext-file-icon.ext-file-icon.file-icon::before,
.show-file-icons .wav-ext-file-icon.ext-file-icon.file-icon::before {
  color: #a074c4;
  content: '\E004';
}
.show-file-icons .babelrc-ext-file-icon.ext-file-icon.file-icon::before {
  color: #cbcb41;
  content: '\E005';
}
.show-file-icons .bowerrc-ext-file-icon.ext-file-icon.file-icon::before,
.show-file-icons
  .bower\.json-name-file-icon.json-ext-file-icon.ext-file-icon.file-icon::before {
  color: #e37933;
  content: '\E006';
}
.show-file-icons .dockerignore-ext-file-icon.ext-file-icon.file-icon::before {
  color: #4d5a5e;
  content: '\E01F';
}
.show-file-icons
  .codeclimate\.yml-ext-file-icon.yml-ext-file-icon.ext-file-icon.file-icon::before {
  color: #8dc149;
  content: '\E011';
}
.show-file-icons .eslintrc-ext-file-icon.ext-file-icon.file-icon::before,
.show-file-icons
  .eslintrc\.js-ext-file-icon.js-ext-file-icon.ext-file-icon.file-icon::before,
.show-file-icons
  .eslintrc\.yaml-ext-file-icon.yaml-ext-file-icon.ext-file-icon.file-icon::before,
.show-file-icons
  .eslintrc\.yml-ext-file-icon.yml-ext-file-icon.ext-file-icon.file-icon::before,
.show-file-icons
  .eslintrc\.json-ext-file-icon.json-ext-file-icon.ext-file-icon.file-icon::before {
  color: #a074c4;
  content: '\E026';
}
.show-file-icons .eslintignore-ext-file-icon.ext-file-icon.file-icon::before {
  color: #4d5a5e;
  content: '\E026';
}
.show-file-icons .firebaserc-ext-file-icon.ext-file-icon.file-icon::before,
.show-file-icons
  .firebase\.json-name-file-icon.json-ext-file-icon.ext-file-icon.file-icon::before {
  color: #e37933;
  content: '\E02A';
}
.show-file-icons .jshintrc-ext-file-icon.ext-file-icon.file-icon::before,
.show-file-icons .jscsrc-ext-file-icon.ext-file-icon.file-icon::before {
  color: #519aba;
  content: '\E047';
}
.show-file-icons .tmp-ext-file-icon.ext-file-icon.file-icon::before {
  color: #6d8086;
  content: '\E00F';
}
.show-file-icons .key-ext-file-icon.ext-file-icon.file-icon::before,
.show-file-icons .cert-ext-file-icon.ext-file-icon.file-icon::before {
  color: #8dc149;
  content: '\E052';
}
.show-file-icons .ds_store-ext-file-icon.ext-file-icon.file-icon::before {
  color: #41535b;
  content: '\E040';
}
.show-file-icons .mix-name-file-icon.ext-file-icon.file-icon::before {
  color: #cc3e44;
  content: '\E03D';
}
.show-file-icons
  .karma\.conf\.js-name-file-icon.conf\.js-ext-file-icon.js-ext-file-icon.ext-file-icon.file-icon::before,
.show-file-icons
  .karma\.conf\.coffee-name-file-icon.conf\.coffee-ext-file-icon.coffee-ext-file-icon.ext-file-icon.file-icon::before {
  color: #8dc149;
  content: '\E04D';
}
.show-file-icons
  .readme\.md-name-file-icon.md-ext-file-icon.ext-file-icon.file-icon::before {
  color: #519aba;
  content: '\E043';
}
.show-file-icons
  .changelog\.md-name-file-icon.md-ext-file-icon.ext-file-icon.file-icon::before,
.show-file-icons .changelog-name-file-icon.ext-file-icon.file-icon::before,
.show-file-icons
  .version\.md-name-file-icon.md-ext-file-icon.ext-file-icon.file-icon::before,
.show-file-icons .version-name-file-icon.ext-file-icon.file-icon::before {
  color: #519aba;
  content: '\E00F';
}
.show-file-icons .mvnw-name-file-icon.ext-file-icon.file-icon::before {
  color: #cc3e44;
  content: '\E056';
}
.show-file-icons .jenkinsfile-name-file-icon.ext-file-icon.file-icon::before {
  color: #cc3e44;
  content: '\E048';
}
.show-file-icons
  .docker-healthcheck-name-file-icon.ext-file-icon.file-icon::before {
  color: #8dc149;
  content: '\E01F';
}
.show-file-icons
  .docker-compose\.yml-name-file-icon.yml-ext-file-icon.ext-file-icon.file-icon::before,
.show-file-icons
  .docker-compose\.yaml-name-file-icon.yaml-ext-file-icon.ext-file-icon.file-icon::before {
  color: #f55385;
  content: '\E01F';
}
.show-file-icons .geckodriver-name-file-icon.ext-file-icon.file-icon::before {
  color: #e37933;
  content: '\E02B';
}
.show-file-icons
  .gruntfile\.js-name-file-icon.js-ext-file-icon.ext-file-icon.file-icon::before,
.show-file-icons
  .gruntfile\.babel\.js-name-file-icon.babel\.js-ext-file-icon.js-ext-file-icon.ext-file-icon.file-icon::before,
.show-file-icons
  .gruntfile\.coffee-name-file-icon.coffee-ext-file-icon.ext-file-icon.file-icon::before {
  color: #e37933;
  content: '\E036';
}
.show-file-icons .gulpfile-name-file-icon.ext-file-icon.file-icon::before {
  color: #cc3e44;
  content: '\E037';
}
.show-file-icons
  .ionic\.config\.json-name-file-icon.config\.json-ext-file-icon.json-ext-file-icon.ext-file-icon.file-icon::before,
.show-file-icons
  .ionic\.project-name-file-icon.project-ext-file-icon.ext-file-icon.file-icon::before {
  color: #519aba;
  content: '\E044';
}
.show-file-icons
  .rollup\.config\.js-name-file-icon.config\.js-ext-file-icon.js-ext-file-icon.ext-file-icon.file-icon::before {
  color: #cc3e44;
  content: '\E06A';
}
.show-file-icons
  .yarn\.clean-name-file-icon.clean-ext-file-icon.ext-file-icon.file-icon::before,
.show-file-icons
  .yarn\.lock-name-file-icon.lock-ext-file-icon.ext-file-icon.file-icon::before {
  color: #519aba;
  content: '\E08A';
}
.show-file-icons
  .webpack\.config\.js-name-file-icon.config\.js-ext-file-icon.js-ext-file-icon.ext-file-icon.file-icon::before,
.show-file-icons
  .webpack\.config\.build\.js-name-file-icon.config\.build\.js-ext-file-icon.build\.js-ext-file-icon.js-ext-file-icon.ext-file-icon.file-icon::before {
  color: #519aba;
  content: '\E084';
}
.show-file-icons .license-name-file-icon.ext-file-icon.file-icon::before,
.show-file-icons .licence-name-file-icon.ext-file-icon.file-icon::before,
.show-file-icons .copying-name-file-icon.ext-file-icon.file-icon::before {
  color: #cbcb41;
  content: '\E04F';
}
.show-file-icons .compiling-name-file-icon.ext-file-icon.file-icon::before {
  color: #e37933;
  content: '\E04F';
}
.show-file-icons .contributing-name-file-icon.ext-file-icon.file-icon::before {
  color: #cc3e44;
  content: '\E04F';
}
.show-file-icons .qmakefile-name-file-icon.ext-file-icon.file-icon::before {
  color: #a074c4;
  content: '\E054';
}
.show-file-icons .omakefile-name-file-icon.ext-file-icon.file-icon::before {
  color: #6d8086;
  content: '\E054';
}
.show-file-icons
  .cmakelists\.txt-name-file-icon.txt-ext-file-icon.ext-file-icon.file-icon::before {
  color: #519aba;
  content: '\E054';
}
.show-file-icons .procfile-name-file-icon.ext-file-icon.file-icon::before {
  color: #a074c4;
  content: '\E03C';
}
.show-file-icons .todo-name-file-icon.ext-file-icon.file-icon::before {
  content: '\E07E';
}
.show-file-icons
  .npm-debug\.log-name-file-icon.log-ext-file-icon.ext-file-icon.file-icon::before {
  color: #41535b;
  content: '\E05B';
}
.vs .show-file-icons .file-icon::before,
.vs .show-file-icons .txt-ext-file-icon.ext-file-icon.file-icon::before {
  color: #bfc2c1;
  content: '\E01D';
}
.vs .show-file-icons .bat-lang-file-icon.file-icon::before {
  color: #498ba7;
  content: '\E086';
}
.vs .show-file-icons .clojure-lang-file-icon.file-icon::before {
  color: #7fae42;
  content: '\E010';
}
.vs .show-file-icons .coffeescript-lang-file-icon.file-icon::before {
  color: #b7b73b;
  content: '\E012';
}
.vs .show-file-icons .c-lang-file-icon.file-icon::before {
  color: #498ba7;
  content: '\E009';
}
.vs .show-file-icons .cpp-lang-file-icon.file-icon::before {
  color: #498ba7;
  content: '\E016';
}
.vs .show-file-icons .csharp-lang-file-icon.file-icon::before {
  color: #498ba7;
  content: '\E008';
}
.vs .show-file-icons .css-lang-file-icon.file-icon::before,
.vs
  .show-file-icons
  .css\.map-ext-file-icon.map-ext-file-icon.ext-file-icon.file-icon::before,
.vs .show-file-icons .sss-ext-file-icon.ext-file-icon.file-icon::before {
  color: #498ba7;
  content: '\E019';
}
.vs .show-file-icons .dockerfile-lang-file-icon.file-icon::before {
  color: #498ba7;
  content: '\E01F';
}
.vs .show-file-icons .fsharp-lang-file-icon.file-icon::before {
  color: #498ba7;
  content: '\E028';
}
.vs .show-file-icons .go-lang-file-icon.file-icon::before {
  color: #498ba7;
  content: '\E033';
}
.vs .show-file-icons .groovy-lang-file-icon.file-icon::before,
.vs .show-file-icons .gsp-ext-file-icon.ext-file-icon.file-icon::before {
  color: #7fae42;
  content: '\E035';
}
.vs .show-file-icons .handlebars-lang-file-icon.file-icon::before,
.vs .show-file-icons .mustache-ext-file-icon.ext-file-icon.file-icon::before,
.vs .show-file-icons .stache-ext-file-icon.ext-file-icon.file-icon::before {
  color: #cc6d2e;
  content: '\E058';
}
.vs .show-file-icons .html-lang-file-icon.file-icon::before {
  color: #cc6d2e;
  content: '\E03E';
}
.vs .show-file-icons .properties-lang-file-icon.file-icon::before,
.vs .show-file-icons .java-lang-file-icon.file-icon::before,
.vs .show-file-icons .class-ext-file-icon.ext-file-icon.file-icon::before,
.vs .show-file-icons .classpath-ext-file-icon.ext-file-icon.file-icon::before {
  color: #b8383d;
  content: '\E046';
}
.vs .show-file-icons .javascriptreact-lang-file-icon.file-icon::before,
.vs .show-file-icons .typescriptreact-lang-file-icon.file-icon::before,
.vs .show-file-icons .cjsx-ext-file-icon.ext-file-icon.file-icon::before {
  color: #498ba7;
  content: '\E069';
}
.vs .show-file-icons .javascript-lang-file-icon.file-icon::before,
.vs
  .show-file-icons
  .js\.map-ext-file-icon.map-ext-file-icon.ext-file-icon.file-icon::before,
.vs .show-file-icons .es-ext-file-icon.ext-file-icon.file-icon::before,
.vs .show-file-icons .es5-ext-file-icon.ext-file-icon.file-icon::before,
.vs .show-file-icons .es7-ext-file-icon.ext-file-icon.file-icon::before {
  color: #b7b73b;
  content: '\E047';
}
.vs .show-file-icons .json-lang-file-icon.file-icon::before,
.vs .show-file-icons .jsonc-lang-file-icon.file-icon::before,
.vs .show-file-icons .cson-ext-file-icon.ext-file-icon.file-icon::before {
  color: #b7b73b;
  content: '\E04B';
}
.vs .show-file-icons .less-lang-file-icon.file-icon::before {
  color: #498ba7;
  content: '\E04E';
}
.vs .show-file-icons .lua-lang-file-icon.file-icon::before {
  color: #498ba7;
  content: '\E053';
}
.vs .show-file-icons .makefile-lang-file-icon.file-icon::before {
  color: #cc6d2e;
  content: '\E054';
}
.vs .show-file-icons .markdown-lang-file-icon.file-icon::before {
  color: #498ba7;
  content: '\E055';
}
.vs .show-file-icons .objective-c-lang-file-icon.file-icon::before {
  color: #b7b73b;
  content: '\E009';
}
.vs .show-file-icons .perl-lang-file-icon.file-icon::before {
  color: #498ba7;
  content: '\E060';
}
.vs .show-file-icons .php-lang-file-icon.file-icon::before,
.vs
  .show-file-icons
  .php\.inc-ext-file-icon.inc-ext-file-icon.ext-file-icon.file-icon::before {
  color: #9068b0;
  content: '\E062';
}
.vs .show-file-icons .powershell-lang-file-icon.file-icon::before {
  color: #498ba7;
  content: '\E063';
}
.vs .show-file-icons .jade-lang-file-icon.file-icon::before {
  color: #b8383d;
  content: '\E045';
}
.vs .show-file-icons .python-lang-file-icon.file-icon::before {
  color: #498ba7;
  content: '\E067';
}
.vs .show-file-icons .ruby-lang-file-icon.file-icon::before {
  color: #b8383d;
  content: '\E06B';
}
.vs .show-file-icons .rust-lang-file-icon.file-icon::before {
  color: #627379;
  content: '\E06C';
}
.vs .show-file-icons .scss-lang-file-icon.file-icon::before,
.vs .show-file-icons .sass-ext-file-icon.ext-file-icon.file-icon::before,
.vs
  .show-file-icons
  .sass-lint\.yml-name-file-icon.yml-ext-file-icon.ext-file-icon.file-icon::before {
  color: #dd4b78;
  content: '\E06E';
}
.vs .show-file-icons .shellscript-lang-file-icon.file-icon::before,
.vs .show-file-icons .fish-ext-file-icon.ext-file-icon.file-icon::before {
  color: #455155;
  content: '\E073';
}
.vs .show-file-icons .sql-lang-file-icon.file-icon::before {
  color: #dd4b78;
  content: '\E01C';
}
.vs .show-file-icons .swift-lang-file-icon.file-icon::before {
  color: #cc6d2e;
  content: '\E07A';
}
.vs .show-file-icons .typescript-lang-file-icon.file-icon::before {
  color: #498ba7;
  content: '\E080';
}
.vs .show-file-icons .xml-lang-file-icon.file-icon::before {
  color: #cc6d2e;
  content: '\E089';
}
.vs .show-file-icons .yaml-lang-file-icon.file-icon::before {
  color: #9068b0;
  content: '\E08B';
}
.vs .show-file-icons .bsl-ext-file-icon.ext-file-icon.file-icon::before {
  color: #b8383d;
  content: '\E007';
}
.vs .show-file-icons .mdo-ext-file-icon.ext-file-icon.file-icon::before {
  color: #b8383d;
  content: '\E057';
}
.vs .show-file-icons .asm-ext-file-icon.ext-file-icon.file-icon::before,
.vs .show-file-icons .s-ext-file-icon.ext-file-icon.file-icon::before {
  color: #b8383d;
  content: '\E003';
}
.vs .show-file-icons .h-ext-file-icon.ext-file-icon.file-icon::before {
  color: #9068b0;
  content: '\E009';
}
.vs .show-file-icons .hh-ext-file-icon.ext-file-icon.file-icon::before,
.vs .show-file-icons .hpp-ext-file-icon.ext-file-icon.file-icon::before,
.vs .show-file-icons .hxx-ext-file-icon.ext-file-icon.file-icon::before {
  color: #9068b0;
  content: '\E016';
}
.vs .show-file-icons .edn-ext-file-icon.ext-file-icon.file-icon::before {
  color: #498ba7;
  content: '\E010';
}
.vs .show-file-icons .cfc-ext-file-icon.ext-file-icon.file-icon::before,
.vs .show-file-icons .cfm-ext-file-icon.ext-file-icon.file-icon::before {
  color: #498ba7;
  content: '\E014';
}
.vs .show-file-icons .config-ext-file-icon.ext-file-icon.file-icon::before,
.vs .show-file-icons .cfg-ext-file-icon.ext-file-icon.file-icon::before,
.vs .show-file-icons .conf-ext-file-icon.ext-file-icon.file-icon::before,
.vs .show-file-icons .toml-ext-file-icon.ext-file-icon.file-icon::before,
.vs .show-file-icons .direnv-ext-file-icon.ext-file-icon.file-icon::before,
.vs .show-file-icons .env-ext-file-icon.ext-file-icon.file-icon::before,
.vs .show-file-icons .static-ext-file-icon.ext-file-icon.file-icon::before,
.vs
  .show-file-icons
  .editorconfig-ext-file-icon.ext-file-icon.file-icon::before,
.vs .show-file-icons .slugignore-ext-file-icon.ext-file-icon.file-icon::before,
.vs .show-file-icons .htaccess-ext-file-icon.ext-file-icon.file-icon::before,
.vs
  .show-file-icons
  .mime\.types-name-file-icon.types-ext-file-icon.ext-file-icon.file-icon::before {
  color: #627379;
  content: '\E015';
}
.vs .show-file-icons .cr-ext-file-icon.ext-file-icon.file-icon::before {
  color: #bfc2c1;
  content: '\E017';
}
.vs .show-file-icons .ecr-ext-file-icon.ext-file-icon.file-icon::before,
.vs .show-file-icons .slang-ext-file-icon.ext-file-icon.file-icon::before {
  color: #bfc2c1;
  content: '\E018';
}
.vs .show-file-icons .csv-ext-file-icon.ext-file-icon.file-icon::before {
  color: #7fae42;
  content: '\E01A';
}
.vs .show-file-icons .xls-ext-file-icon.ext-file-icon.file-icon::before,
.vs .show-file-icons .xlsx-ext-file-icon.ext-file-icon.file-icon::before {
  color: #7fae42;
  content: '\E088';
}
.vs .show-file-icons .cake-ext-file-icon.ext-file-icon.file-icon::before {
  color: #b8383d;
  content: '\E00A';
}
.vs .show-file-icons .ctp-ext-file-icon.ext-file-icon.file-icon::before {
  color: #b8383d;
  content: '\E00B';
}
.vs .show-file-icons .d-ext-file-icon.ext-file-icon.file-icon::before {
  color: #b8383d;
  content: '\E01B';
}
.vs .show-file-icons .doc-ext-file-icon.ext-file-icon.file-icon::before,
.vs .show-file-icons .docx-ext-file-icon.ext-file-icon.file-icon::before {
  color: #498ba7;
  content: '\E087';
}
.vs .show-file-icons .ejs-ext-file-icon.ext-file-icon.file-icon::before {
  color: #b7b73b;
  content: '\E021';
}
.vs .show-file-icons .ex-ext-file-icon.ext-file-icon.file-icon::before {
  color: #9068b0;
  content: '\E022';
}
.vs .show-file-icons .exs-ext-file-icon.ext-file-icon.file-icon::before {
  color: #9068b0;
  content: '\E023';
}
.vs .show-file-icons .elm-ext-file-icon.ext-file-icon.file-icon::before {
  color: #498ba7;
  content: '\E024';
}
.vs .show-file-icons .ico-ext-file-icon.ext-file-icon.file-icon::before {
  color: #b7b73b;
  content: '\E029';
}
.vs .show-file-icons .gitignore-ext-file-icon.ext-file-icon.file-icon::before,
.vs .show-file-icons .gitconfig-ext-file-icon.ext-file-icon.file-icon::before,
.vs .show-file-icons .gitkeep-ext-file-icon.ext-file-icon.file-icon::before,
.vs
  .show-file-icons
  .gitattributes-ext-file-icon.ext-file-icon.file-icon::before,
.vs .show-file-icons .gitmodules-ext-file-icon.ext-file-icon.file-icon::before {
  color: #3b4b52;
  content: '\E02E';
}
.vs .show-file-icons .slide-ext-file-icon.ext-file-icon.file-icon::before,
.vs .show-file-icons .article-ext-file-icon.ext-file-icon.file-icon::before {
  color: #498ba7;
  content: '\E032';
}
.vs .show-file-icons .gradle-ext-file-icon.ext-file-icon.file-icon::before {
  color: #7fae42;
  content: '\E034';
}
.vs .show-file-icons .haml-ext-file-icon.ext-file-icon.file-icon::before {
  color: #b8383d;
  content: '\E039';
}
.vs .show-file-icons .hs-ext-file-icon.ext-file-icon.file-icon::before,
.vs .show-file-icons .lhs-ext-file-icon.ext-file-icon.file-icon::before {
  color: #9068b0;
  content: '\E03A';
}
.vs .show-file-icons .hx-ext-file-icon.ext-file-icon.file-icon::before {
  color: #cc6d2e;
  content: '\E03B';
}
.vs .show-file-icons .hxs-ext-file-icon.ext-file-icon.file-icon::before {
  color: #b7b73b;
  content: '\E03B';
}
.vs .show-file-icons .hxp-ext-file-icon.ext-file-icon.file-icon::before {
  color: #498ba7;
  content: '\E03B';
}
.vs .show-file-icons .hxml-ext-file-icon.ext-file-icon.file-icon::before {
  color: #9068b0;
  content: '\E03B';
}
.vs
  .show-file-icons
  .spec\.js-ext-file-icon.js-ext-file-icon.ext-file-icon.file-icon::before {
  color: #cc6d2e;
  content: '\E047';
}
.vs .show-file-icons .jinja-ext-file-icon.ext-file-icon.file-icon::before,
.vs .show-file-icons .jinja2-ext-file-icon.ext-file-icon.file-icon::before {
  color: #b8383d;
  content: '\E049';
}
.vs .show-file-icons .jl-ext-file-icon.ext-file-icon.file-icon::before {
  color: #9068b0;
  content: '\E04C';
}
.vs .show-file-icons .liquid-ext-file-icon.ext-file-icon.file-icon::before {
  color: #7fae42;
  content: '\E050';
}
.vs .show-file-icons .ls-ext-file-icon.ext-file-icon.file-icon::before {
  color: #498ba7;
  content: '\E051';
}
.vs .show-file-icons .njk-ext-file-icon.ext-file-icon.file-icon::before,
.vs .show-file-icons .nunjucks-ext-file-icon.ext-file-icon.file-icon::before,
.vs .show-file-icons .nunjs-ext-file-icon.ext-file-icon.file-icon::before,
.vs .show-file-icons .nunj-ext-file-icon.ext-file-icon.file-icon::before,
.vs .show-file-icons .njs-ext-file-icon.ext-file-icon.file-icon::before,
.vs .show-file-icons .nj-ext-file-icon.ext-file-icon.file-icon::before {
  color: #7fae42;
  content: '\E05C';
}
.vs
  .show-file-icons
  .npm-debug\.log-ext-file-icon.log-ext-file-icon.ext-file-icon.file-icon::before {
  color: #3b4b52;
  content: '\E05A';
}
.vs .show-file-icons .npmignore-ext-file-icon.ext-file-icon.file-icon::before,
.vs .show-file-icons .npmrc-ext-file-icon.ext-file-icon.file-icon::before {
  color: #b8383d;
  content: '\E05A';
}
.vs .show-file-icons .ml-ext-file-icon.ext-file-icon.file-icon::before,
.vs .show-file-icons .mli-ext-file-icon.ext-file-icon.file-icon::before,
.vs .show-file-icons .cmx-ext-file-icon.ext-file-icon.file-icon::before,
.vs .show-file-icons .cmxa-ext-file-icon.ext-file-icon.file-icon::before {
  color: #cc6d2e;
  content: '\E05D';
}
.vs .show-file-icons .odata-ext-file-icon.ext-file-icon.file-icon::before {
  color: #cc6d2e;
  content: '\E05E';
}
.vs .show-file-icons .pug-ext-file-icon.ext-file-icon.file-icon::before {
  color: #b8383d;
  content: '\E065';
}
.vs .show-file-icons .pp-ext-file-icon.ext-file-icon.file-icon::before,
.vs .show-file-icons .epp-ext-file-icon.ext-file-icon.file-icon::before {
  color: #b7b73b;
  content: '\E066';
}
.vs .show-file-icons .erb-ext-file-icon.ext-file-icon.file-icon::before,
.vs
  .show-file-icons
  .erb\.html-ext-file-icon.html-ext-file-icon.ext-file-icon.file-icon::before,
.vs
  .show-file-icons
  .html\.erb-ext-file-icon.erb-ext-file-icon.ext-file-icon.file-icon::before {
  color: #b8383d;
  content: '\E03F';
}
.vs
  .show-file-icons
  .springbeans-ext-file-icon.ext-file-icon.file-icon::before {
  color: #7fae42;
  content: '\E076';
}
.vs .show-file-icons .slim-ext-file-icon.ext-file-icon.file-icon::before {
  color: #cc6d2e;
  content: '\E074';
}
.vs
  .show-file-icons
  .smarty\.tpl-ext-file-icon.tpl-ext-file-icon.ext-file-icon.file-icon::before {
  color: #b7b73b;
  content: '\E075';
}
.vs .show-file-icons .sbt-ext-file-icon.ext-file-icon.file-icon::before {
  color: #498ba7;
  content: '\E06F';
}
.vs .show-file-icons .scala-ext-file-icon.ext-file-icon.file-icon::before {
  color: #b8383d;
  content: '\E070';
}
.vs .show-file-icons .sol-ext-file-icon.ext-file-icon.file-icon::before {
  color: #498ba7;
  content: '\E027';
}
.vs .show-file-icons .styl-ext-file-icon.ext-file-icon.file-icon::before {
  color: #7fae42;
  content: '\E077';
}
.vs .show-file-icons .tf-ext-file-icon.ext-file-icon.file-icon::before,
.vs
  .show-file-icons
  .tf\.json-ext-file-icon.json-ext-file-icon.ext-file-icon.file-icon::before,
.vs .show-file-icons .tfvars-ext-file-icon.ext-file-icon.file-icon::before {
  color: #9068b0;
  content: '\E07B';
}
.vs .show-file-icons .tex-ext-file-icon.ext-file-icon.file-icon::before {
  color: #498ba7;
  content: '\E07C';
}
.vs .show-file-icons .sty-ext-file-icon.ext-file-icon.file-icon::before {
  color: #b7b73b;
  content: '\E07C';
}
.vs .show-file-icons .dtx-ext-file-icon.ext-file-icon.file-icon::before {
  color: #cc6d2e;
  content: '\E07C';
}
.vs .show-file-icons .ins-ext-file-icon.ext-file-icon.file-icon::before {
  color: #bfc2c1;
  content: '\E07C';
}
.vs .show-file-icons .twig-ext-file-icon.ext-file-icon.file-icon::before {
  color: #7fae42;
  content: '\E07F';
}
.vs
  .show-file-icons
  .spec\.ts-ext-file-icon.ts-ext-file-icon.ext-file-icon.file-icon::before {
  color: #b7b73b;
  content: '\E080';
}
.vs .show-file-icons .vala-ext-file-icon.ext-file-icon.file-icon::before,
.vs .show-file-icons .vapi-ext-file-icon.ext-file-icon.file-icon::before {
  color: #627379;
  content: '\E081';
}
.vs .show-file-icons .vue-ext-file-icon.ext-file-icon.file-icon::before {
  color: #7fae42;
  content: '\E083';
}
.vs .show-file-icons .jar-ext-file-icon.ext-file-icon.file-icon::before {
  color: #b8383d;
  content: '\E08C';
}
.vs .show-file-icons .zip-ext-file-icon.ext-file-icon.file-icon::before {
  color: #627379;
  content: '\E08C';
}
.vs .show-file-icons .wgt-ext-file-icon.ext-file-icon.file-icon::before {
  color: #498ba7;
  content: '\E085';
}
.vs .show-file-icons .ai-ext-file-icon.ext-file-icon.file-icon::before {
  color: #b7b73b;
  content: '\E041';
}
.vs .show-file-icons .psd-ext-file-icon.ext-file-icon.file-icon::before {
  color: #498ba7;
  content: '\E061';
}
.vs .show-file-icons .pdf-ext-file-icon.ext-file-icon.file-icon::before {
  color: #b8383d;
  content: '\E05F';
}
.vs .show-file-icons .eot-ext-file-icon.ext-file-icon.file-icon::before,
.vs .show-file-icons .ttf-ext-file-icon.ext-file-icon.file-icon::before,
.vs .show-file-icons .woff-ext-file-icon.ext-file-icon.file-icon::before,
.vs .show-file-icons .woff2-ext-file-icon.ext-file-icon.file-icon::before {
  color: #b8383d;
  content: '\E02D';
}
.vs .show-file-icons .gif-ext-file-icon.ext-file-icon.file-icon::before,
.vs .show-file-icons .jpg-ext-file-icon.ext-file-icon.file-icon::before,
.vs .show-file-icons .jpeg-ext-file-icon.ext-file-icon.file-icon::before,
.vs .show-file-icons .png-ext-file-icon.ext-file-icon.file-icon::before,
.vs .show-file-icons .pxm-ext-file-icon.ext-file-icon.file-icon::before,
.vs .show-file-icons .svgx-ext-file-icon.ext-file-icon.file-icon::before {
  color: #9068b0;
  content: '\E042';
}
.vs .show-file-icons .svg-ext-file-icon.ext-file-icon.file-icon::before {
  color: #9068b0;
  content: '\E079';
}
.vs
  .show-file-icons
  .sublime-project-ext-file-icon.ext-file-icon.file-icon::before,
.vs
  .show-file-icons
  .sublime-workspace-ext-file-icon.ext-file-icon.file-icon::before {
  color: #cc6d2e;
  content: '\E078';
}
.vs .show-file-icons .component-ext-file-icon.ext-file-icon.file-icon::before,
.vs .show-file-icons .cls-ext-file-icon.ext-file-icon.file-icon::before {
  color: #498ba7;
  content: '\E06D';
}
.vs .show-file-icons .mov-ext-file-icon.ext-file-icon.file-icon::before,
.vs .show-file-icons .ogv-ext-file-icon.ext-file-icon.file-icon::before,
.vs .show-file-icons .webm-ext-file-icon.ext-file-icon.file-icon::before,
.vs .show-file-icons .avi-ext-file-icon.ext-file-icon.file-icon::before,
.vs .show-file-icons .mpg-ext-file-icon.ext-file-icon.file-icon::before,
.vs .show-file-icons .mp4-ext-file-icon.ext-file-icon.file-icon::before {
  color: #dd4b78;
  content: '\E082';
}
.vs .show-file-icons .mp3-ext-file-icon.ext-file-icon.file-icon::before,
.vs .show-file-icons .ogg-ext-file-icon.ext-file-icon.file-icon::before,
.vs .show-file-icons .wav-ext-file-icon.ext-file-icon.file-icon::before {
  color: #9068b0;
  content: '\E004';
}
.vs .show-file-icons .babelrc-ext-file-icon.ext-file-icon.file-icon::before {
  color: #b7b73b;
  content: '\E005';
}
.vs .show-file-icons .bowerrc-ext-file-icon.ext-file-icon.file-icon::before,
.vs
  .show-file-icons
  .bower\.json-name-file-icon.json-ext-file-icon.ext-file-icon.file-icon::before {
  color: #cc6d2e;
  content: '\E006';
}
.vs
  .show-file-icons
  .dockerignore-ext-file-icon.ext-file-icon.file-icon::before {
  color: #455155;
  content: '\E01F';
}
.vs
  .show-file-icons
  .codeclimate\.yml-ext-file-icon.yml-ext-file-icon.ext-file-icon.file-icon::before {
  color: #7fae42;
  content: '\E011';
}
.vs .show-file-icons .eslintrc-ext-file-icon.ext-file-icon.file-icon::before,
.vs
  .show-file-icons
  .eslintrc\.js-ext-file-icon.js-ext-file-icon.ext-file-icon.file-icon::before,
.vs
  .show-file-icons
  .eslintrc\.yaml-ext-file-icon.yaml-ext-file-icon.ext-file-icon.file-icon::before,
.vs
  .show-file-icons
  .eslintrc\.yml-ext-file-icon.yml-ext-file-icon.ext-file-icon.file-icon::before,
.vs
  .show-file-icons
  .eslintrc\.json-ext-file-icon.json-ext-file-icon.ext-file-icon.file-icon::before {
  color: #9068b0;
  content: '\E026';
}
.vs
  .show-file-icons
  .eslintignore-ext-file-icon.ext-file-icon.file-icon::before {
  color: #455155;
  content: '\E026';
}
.vs .show-file-icons .firebaserc-ext-file-icon.ext-file-icon.file-icon::before,
.vs
  .show-file-icons
  .firebase\.json-name-file-icon.json-ext-file-icon.ext-file-icon.file-icon::before {
  color: #cc6d2e;
  content: '\E02A';
}
.vs .show-file-icons .jshintrc-ext-file-icon.ext-file-icon.file-icon::before,
.vs .show-file-icons .jscsrc-ext-file-icon.ext-file-icon.file-icon::before {
  color: #498ba7;
  content: '\E047';
}
.vs .show-file-icons .tmp-ext-file-icon.ext-file-icon.file-icon::before {
  color: #627379;
  content: '\E00F';
}
.vs .show-file-icons .key-ext-file-icon.ext-file-icon.file-icon::before,
.vs .show-file-icons .cert-ext-file-icon.ext-file-icon.file-icon::before {
  color: #7fae42;
  content: '\E052';
}
.vs .show-file-icons .ds_store-ext-file-icon.ext-file-icon.file-icon::before {
  color: #3b4b52;
  content: '\E040';
}
.vs .show-file-icons .mix-name-file-icon.ext-file-icon.file-icon::before {
  color: #b8383d;
  content: '\E03D';
}
.vs
  .show-file-icons
  .karma\.conf\.js-name-file-icon.conf\.js-ext-file-icon.js-ext-file-icon.ext-file-icon.file-icon::before,
.vs
  .show-file-icons
  .karma\.conf\.coffee-name-file-icon.conf\.coffee-ext-file-icon.coffee-ext-file-icon.ext-file-icon.file-icon::before {
  color: #7fae42;
  content: '\E04D';
}
.vs
  .show-file-icons
  .readme\.md-name-file-icon.md-ext-file-icon.ext-file-icon.file-icon::before {
  color: #498ba7;
  content: '\E043';
}
.vs
  .show-file-icons
  .changelog\.md-name-file-icon.md-ext-file-icon.ext-file-icon.file-icon::before,
.vs .show-file-icons .changelog-name-file-icon.ext-file-icon.file-icon::before,
.vs
  .show-file-icons
  .version\.md-name-file-icon.md-ext-file-icon.ext-file-icon.file-icon::before,
.vs .show-file-icons .version-name-file-icon.ext-file-icon.file-icon::before {
  color: #498ba7;
  content: '\E00F';
}
.vs .show-file-icons .mvnw-name-file-icon.ext-file-icon.file-icon::before {
  color: #b8383d;
  content: '\E056';
}
.vs
  .show-file-icons
  .jenkinsfile-name-file-icon.ext-file-icon.file-icon::before {
  color: #b8383d;
  content: '\E048';
}
.vs
  .show-file-icons
  .docker-healthcheck-name-file-icon.ext-file-icon.file-icon::before {
  color: #7fae42;
  content: '\E01F';
}
.vs
  .show-file-icons
  .docker-compose\.yml-name-file-icon.yml-ext-file-icon.ext-file-icon.file-icon::before,
.vs
  .show-file-icons
  .docker-compose\.yaml-name-file-icon.yaml-ext-file-icon.ext-file-icon.file-icon::before {
  color: #dd4b78;
  content: '\E01F';
}
.vs
  .show-file-icons
  .geckodriver-name-file-icon.ext-file-icon.file-icon::before {
  color: #cc6d2e;
  content: '\E02B';
}
.vs
  .show-file-icons
  .gruntfile\.js-name-file-icon.js-ext-file-icon.ext-file-icon.file-icon::before,
.vs
  .show-file-icons
  .gruntfile\.babel\.js-name-file-icon.babel\.js-ext-file-icon.js-ext-file-icon.ext-file-icon.file-icon::before,
.vs
  .show-file-icons
  .gruntfile\.coffee-name-file-icon.coffee-ext-file-icon.ext-file-icon.file-icon::before {
  color: #cc6d2e;
  content: '\E036';
}
.vs .show-file-icons .gulpfile-name-file-icon.ext-file-icon.file-icon::before {
  color: #b8383d;
  content: '\E037';
}
.vs
  .show-file-icons
  .ionic\.config\.json-name-file-icon.config\.json-ext-file-icon.json-ext-file-icon.ext-file-icon.file-icon::before,
.vs
  .show-file-icons
  .ionic\.project-name-file-icon.project-ext-file-icon.ext-file-icon.file-icon::before {
  color: #498ba7;
  content: '\E044';
}
.vs
  .show-file-icons
  .rollup\.config\.js-name-file-icon.config\.js-ext-file-icon.js-ext-file-icon.ext-file-icon.file-icon::before {
  color: #b8383d;
  content: '\E06A';
}
.vs
  .show-file-icons
  .yarn\.clean-name-file-icon.clean-ext-file-icon.ext-file-icon.file-icon::before,
.vs
  .show-file-icons
  .yarn\.lock-name-file-icon.lock-ext-file-icon.ext-file-icon.file-icon::before {
  color: #498ba7;
  content: '\E08A';
}
.vs
  .show-file-icons
  .webpack\.config\.js-name-file-icon.config\.js-ext-file-icon.js-ext-file-icon.ext-file-icon.file-icon::before,
.vs
  .show-file-icons
  .webpack\.config\.build\.js-name-file-icon.config\.build\.js-ext-file-icon.build\.js-ext-file-icon.js-ext-file-icon.ext-file-icon.file-icon::before {
  color: #498ba7;
  content: '\E084';
}
.vs .show-file-icons .license-name-file-icon.ext-file-icon.file-icon::before,
.vs .show-file-icons .licence-name-file-icon.ext-file-icon.file-icon::before,
.vs .show-file-icons .copying-name-file-icon.ext-file-icon.file-icon::before {
  color: #b7b73b;
  content: '\E04F';
}
.vs .show-file-icons .compiling-name-file-icon.ext-file-icon.file-icon::before {
  color: #cc6d2e;
  content: '\E04F';
}
.vs
  .show-file-icons
  .contributing-name-file-icon.ext-file-icon.file-icon::before {
  color: #b8383d;
  content: '\E04F';
}
.vs .show-file-icons .qmakefile-name-file-icon.ext-file-icon.file-icon::before {
  color: #9068b0;
  content: '\E054';
}
.vs .show-file-icons .omakefile-name-file-icon.ext-file-icon.file-icon::before {
  color: #627379;
  content: '\E054';
}
.vs
  .show-file-icons
  .cmakelists\.txt-name-file-icon.txt-ext-file-icon.ext-file-icon.file-icon::before {
  color: #498ba7;
  content: '\E054';
}
.vs .show-file-icons .procfile-name-file-icon.ext-file-icon.file-icon::before {
  color: #9068b0;
  content: '\E03C';
}
.vs
  .show-file-icons
  .npm-debug\.log-name-file-icon.log-ext-file-icon.ext-file-icon.file-icon::before {
  color: #3b4b52;
  content: '\E05B';
}
